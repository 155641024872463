.form-group > div {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.form-group {
    .form-control-label {
        float: left;
        display: inline-table;
        width: auto;
        height: calc(2.0625rem + 2px);
        padding: 0.375rem 0.5rem 0.375rem 0;
        margin: 0.8rem 0 0.8rem 0;
        font-size: 0.875rem;
        line-height: 1.5;
        color: var(--primary);
        border-bottom: 1px solid var(--primary);
        border-radius: 0;
        white-space: nowrap;
    }
}
.form-control {
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0;
    margin: 0.8rem 0;
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--primary);
    background-color: var(--light);
    background-clip: padding-box;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid var(--primary);
    border-radius: 0;
    -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;

    &:disabled {
        background-color: var(--light);
        color: var(--gray);
    }
    &[readonly] {
        background-color: var(--light);
        color: var(--gray);
    }
}

.group-append {
    .form-control {
        margin-right: 0;
    }
    .input-group-text {
        height: calc(2.0625rem + 2px);
        padding: 0.375rem;
        margin: 0.8rem 0rem 0.8rem 0;
        font-size: 0.875rem;
        line-height: 1.5;
        color: var(--primary);
        background-color: var(--light);
        background-clip: padding-box;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid var(--primary);
        border-radius: 0;
    }
}
textarea,
.textarea {
    min-height: 200px;
    background-size: 100% 100%, 100% 100%, 100% 31px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    line-height: 31px;
    padding: 8px;
}
textarea,
input,
select {
    text-indent: 10px;
}

// 2DO refactor auf anderes Image
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0px;
    background-image: url("https://image.flaticon.com/icons/svg/203/203484.svg");
    background-position: 99% 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    margin-bottom: 1rem !important;
}

.table {
    td {
        vertical-align: middle;
    }
    table {
        width: 100%;
    }
}

//disabled state styles

.form-group {
    :disabled,
    .disabled {
        opacity: 0.3;
    }
}
