// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@font-face {
  font-family: 'Trajan';
  src: url('../assets/fonts/Trajan/Trajan-Bold.eot');
  src:
    url('../assets/fonts/Trajan/Trajan-Bold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Trajan/Trajan-Bold.woff2') format('woff2'),
    url('../assets/fonts/Trajan/Trajan-Bold.woff') format('woff'),
    url('../assets/fonts/Trajan/Trajan-Bold.ttf') format('truetype'),
    url('../assets/fonts/Trajan/Trajan-Bold.svg#Trajan-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans.eot');
  src:
    url('../assets/fonts/OpenSans/OpenSans.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/OpenSans/OpenSans.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans.woff') format('woff'),
    url('../assets/fonts/OpenSans/OpenSans.ttf') format('truetype'),
    url('../assets/fonts/OpenSans/OpenSans.svg#OpenSans') format('svg');
  font-weight: normal;
  font-style: normal;
}
$theme-colors: (
  primary: #222,
  danger: #C46D61,
  red: #C46D61,
  success: #99B97E,
  green: #99B97E,
  grey: #888,
  background-grey: #dbdbdb,
  "light": #fff
);

$enable-rounded: false;

$body-bg: var(--background-grey);

$dropdown-link-hover-bg: var(--primary);
$dropdown-link-active-bg: var(--primary);
$dropdown-link-hover-color: var(--light);

$font-family-base: 'Open Sans',
// Safari for OS X and iOS (San Francisco)
-apple-system,
// Chrome < 56 for OS X (San Francisco)
BlinkMacSystemFont,
// Windows
"Segoe UI",
// Android
"Roboto",
// Basic web fallback
"Helvetica Neue", Arial, sans-serif,
// Emoji fonts
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-serif: Trajan, serif;

html,
body {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5
  h6, .h6,
  .headline {
    font-family: Trajan, serif;
  }
}

.bg-danger, .bg-dark, .bg-info, .bg-primary, .bg-success, .bg-warning {
    color: #fff;
}
