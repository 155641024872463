
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url("https://image.flaticon.com/icons/svg/203/203484.svg");
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  margin-bottom: 1rem !important;
  
  text-indent: 10px;
}