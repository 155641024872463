// Override the Bootstrap defaults without modifying key, versioned files.

.estates {
  .card {
    .nav-link {
      color: var(--grey);
      font-family: Trajan;

      &.active {
        background: var(--light);
        color: var(--primary);
        @include hover-focus {
          color: var(--primary);
        }
      }
      @include hover-focus {
        color: var(--light);
      }
    }
  }
}

#navbarSupportedContent {
  .nav-item {
    .nav-link {
      &.avatar {
        > img {
          height: 35px;
          margin: 0 10px;
        }
      }
      > .img-avatar {
        height: 35px;
        margin: 0 10px;
      }
    }
  }
}

.nav-tabs .nav-link {
    text-transform: uppercase;
    font-family: $font-serif;
    font-size: 0.875rem;

    &:not(.active){
        background-color: var(--primary);
        color: white;
    }
}
