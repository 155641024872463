.card {
  border: none;
  border-radius: 0;
  &-header {
    font-family: Trajan;
    text-align: center;
  }
  
  &-footer {
    background: white;
    border-top: none;
  }
}
