.form-control {
  height: calc(2.0625rem + 2px);
  padding: 0.375rem 0;
  margin: 0.8rem 1rem 0.8rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--primary);
  background-color: var(--light);
  background-clip: padding-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--primary);
  border-radius: 0;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

  &:disabled {
    background-color: var(--light);
    color: var(--gray);
  }
  &[readonly] {
    background-color: var(--light);
    color: var(--gray);
  }
}