.v-timeline {
  padding-top: 24px;
  position: relative;
}
.theme--light.v-timeline:before {
  background: rgba(0,0,0,0.12);
}
.v-timeline:before {
  bottom: 0;
  content: '';
  height: 100%;
  left: calc(50% - 1px);
  position: absolute;
  top: 0;
  width: 2px;
}
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right), 
.v-timeline-item--left {
  flex-direction: row;
}

.v-timeline-item {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 24px;
}
.theme--light.v-timeline .v-timeline-item__dot {
  background: #fff;
}

.v-timeline-item__dot {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  align-self: center;
  position: absolute;
  height: 38px;
  left: calc(50% - 19px);
  width: 38px;
}
.v-timeline-item__dot, .v-timeline-item__inner-dot {
  border-radius: 50%;
}
.v-timeline-item__dot .v-timeline-item__inner-dot {
  height: 30px;
  margin: 4px;
  width: 30px;
}
.v-timeline-item__dot--large .v-timeline-item__inner-dot {
  height: 42px;
  margin: 5px;
  width: 42px;
}
.v-timeline-item__dot--large {
  height: 52px;
  left: calc(50% - 26px);
  width: 52px;
}

.v-timeline-item__inner-dot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-timeline-item__dot, .v-timeline-item__inner-dot {
  border-radius: 50%;
}
.v-avatar {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.v-avatar img, .v-avatar .v-icon, .v-avatar .v-image {
  border-radius: 50%;
  display: inline-flex;
  height: inherit;
  width: inherit;
}
.v-timeline-item__body {
  position: relative;
  height: 100%;
  flex: 1 1 100%;
  max-width: calc(50% - 48px);
}
.theme--light.v-sheet {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0,0,0,0.87);
}
.theme--light.v-card {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0,0,0,0.87);
}

.v-sheet {
  display: block;
  border-radius: 2px;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-card {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  text-decoration: none;
}
.elevation-2 {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:before, 
.v-timeline-item--left .v-card:before, 
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-card:after, 
.v-timeline-item--left .v-card:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  left: 100%;
}

.theme--light.v-timeline .v-timeline-item .v-card:before {
  border-right-color: rgba(0,0,0,0.12);
}
.v-timeline-item .v-card:before {
  top: calc(50% - 10px + 2px);
}
.v-timeline-item .v-card:before, .v-timeline-item .v-card:after {
  content: '';
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #000;
  top: calc(50% - 10px);
}
.v-card > *:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.v-card__title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
}
.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-card__text {
  padding: 16px;
  width: 100%;
}
.v-timeline-item:nth-child(odd):not(.v-timeline-item--right) .v-timeline-item__opposite, 
.v-timeline-item--left .v-timeline-item__opposite {
  margin-left: 96px;
  text-align: left;
}

.v-timeline-item__opposite {
  flex: 1 1 auto;
  align-self: center;
  max-width: calc(50% - 48px);
}

.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-card:before, 
.v-timeline-item--right .v-card:before, 
.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-card:after, 
.v-timeline-item--right .v-card:after {
    right: 100%;
}

.v-timeline-item:nth-child(even):not(.v-timeline-item--left) .v-timeline-item__opposite, 
.v-timeline-item--right .v-timeline-item__opposite {
    margin-right: 96px;
    text-align: right;
}