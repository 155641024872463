.paul-index-page {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;

    @include media-breakpoint-down(sm) {
        height: initial;
    }

    .inner-box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: auto;
        @include media-breakpoint-down(sm) {
            top: initial;
            transform: initial;
            position: relative;
        }

        &__content {
            color: white;
            text-align: center;
            img {
                padding: 30px;
                width: 100%;
                max-width: 380px;
            }

            a {
                color: white;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }

                &.login-button {
                    display: inline-block;
                    margin-top: 1rem;
                    margin-bottom: 3rem;
                    color: white;
                    padding: 10px 40px;
                    border: 1px solid white;
                    text-decoration: none;

                    &:hover {
                        color: #333;
                        background: white;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .inner-box.footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20px;
        margin: auto;
        top: initial;

        @include media-breakpoint-down(sm) {
            position: relative;
        }


        .footer-links-index {
            padding: 15px 0;

            div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 0 auto;

                a {
                    color: white;
                    padding: 0 15px;
                    text-transform: uppercase;
                }
            }
        }
    }
}


.splash {
    .splash-header {
        height: 100px;
        background-color: white;
        border-bottom: 1px solid #333;

        img {
            padding:15px 50px 15px 50px;
            height: 100%;
        }
    }

    .footer-links {
        position: fixed;
        bottom: 0;
        padding: 15px 0;
        background-color: var(--grey);

        div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 0 auto;

            a {
                padding: 0 15px;
                text-transform: uppercase;
            }
        }
    }
}
